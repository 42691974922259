export const environment = {
  production: false,
  apiUrl: 'https://demo02-api.xtension.app',
  baseUrl: 'https://demo02.xtension.app',
  auth0: {
    domain: 'demo02-xtension-b2b.us.auth0.com',
    clientId: 'aodjmfn2J4tSYZULdsbCMAzhh8Qrd9b3',
    audience: 'https://demo02-api.xtension.app',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://demo02-api.xtension.app/*',
        },
      ],
    },
  },
  auth0API: {
    domain: 'https://demo02-xtension-b2b.us.auth0.com',
    apiTokenUrl: 'https://demo02-xtension-b2b.us.auth0.com/oauth/token',
    audience: 'https://demo02-xtension-b2b.us.auth0.com/api/v2/',
    clientId: 'w8QR3NNCXMBZWHcaJNAJhjtCU9uZXL0b',
    clientSecret:
      'yr2q2eGQztNfwDiImq3MOZ72A1G8nRu9GX4JvuGtfMh1PbprHTzwiv5IxIoJwgrU',
  },
};
